
import { defineComponent, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Moment } from 'moment'
import { Modal, message } from 'ant-design-vue'
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue'
import UploadFileNameSelector from '@/views/LocalRegistration/components/UploadFileNameSelector.vue'
import Upload from '@/views/LocalRegistration/components/Upload.vue'
import type {
  ThirdPartyUploadSearchParams,
  ThirdPartyDataUploadData
} from '@/views/LocalRegistration/type'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import {
  thirdPartyUploadTemplate as thirdPartyUploadTemplateAPI,
  thirdPartyUploadImport as thirdPartyUploadImportAPI,
  thirdPartyUploadList as thirdPartyUploadListAPI,
  thirdPartyUploadExport as thirdPartyUploadExportAPI,
  thirdPartyUploadDelete as thirdPartyUploadDeleteAPI
} from '@/API/localRegistration'

export default defineComponent({
  components: {
    BuSelector,
    UploadFileNameSelector,
    Upload
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const conditions = reactive({
      bu: undefined as string|undefined,
      beginTime: undefined as Moment|undefined,
      endTime: undefined as Moment|undefined,
      filenames: [] as string[],
      vin: undefined as string|undefined
    })

    const searchParams: ThirdPartyUploadSearchParams = {
      bu: undefined,
      beginTime: undefined,
      endTime: undefined,
      filenames: undefined,
      vin: undefined
    }

    const handleReset = () => {
      conditions.bu = undefined
      conditions.beginTime = undefined
      conditions.endTime = undefined
      conditions.filenames = []
      conditions.vin = undefined
    }

    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0
    })

    const columns = [
      {
        title: 'VIN',
        dataIndex: 'vin'
      },
      {
        title: '厂商',
        dataIndex: 'dealer'
      },
      {
        title: '品牌',
        dataIndex: 'brand'
      },
      {
        title: '车系',
        dataIndex: 'typeClass'
      },
      {
        title: '车型',
        dataIndex: 'model'
      },
      {
        title: '排量CC',
        dataIndex: 'displacement'
      },
      {
        title: '省',
        dataIndex: 'province'
      },
      {
        title: '地级市',
        dataIndex: 'city'
      },
      {
        title: '上牌年月',
        dataIndex: 'retailYearMonth'
      },
      {
        title: '上牌日期',
        dataIndex: 'retailTime'
      },
      {
        title: '车身颜色',
        dataIndex: 'color'
      },
      {
        title: '车牌号前2位',
        dataIndex: 'license'
      },
      {
        title: '上传时间',
        dataIndex: 'createdAt'
      },
      {
        title: '上传文件名称',
        dataIndex: 'filename'
      },
      {
        title: '操作',
        align: 'center',
        slots: { customRender: 'Operation' }
      }
    ]

    const data = ref<ThirdPartyDataUploadData[]>([])

    const queryThirdPartyData = async () => {
      const response = await thirdPartyUploadListAPI(searchParams, pagination.current, pagination.pageSize)
      data.value = response.content
      pagination.total = response.totalElements
    }

    const handleSearch = () => {
      searchParams.bu = conditions.bu ?? undefined
      searchParams.beginTime = conditions.beginTime ? conditions.beginTime.format('YYYY-MM-DD') : undefined,
      searchParams.endTime = conditions.endTime ? conditions.endTime.format('YYYY-MM-DD') : undefined,
      searchParams.filenames = conditions.filenames && conditions.filenames.length ? conditions.filenames : undefined
      searchParams.vin = conditions.vin ?? undefined

      pagination.current = 1

      queryThirdPartyData()
    }

    const handleExport = async () => {
      const res = await thirdPartyUploadExportAPI(searchParams)
      downloadFromStream(res)
    }

    const visibleUploadDrawer = ref(false)

    const handleToUpload = () => {
      visibleUploadDrawer.value = true
    }

    const handleDownloadTemplate = async () => {
      const res = await thirdPartyUploadTemplateAPI()
      downloadFromStream(res)
    }

    const uploadBu = ref<string>()

    const handleUpload = async (file: File, resolve: (value: unknown) => void, reject: (reason?: unknown) => void, options: any) => {
      try {
        const data = await thirdPartyUploadImportAPI(uploadBu.value as string, file, {
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent.lengthComputable) {
              const percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
              options.percent.value = percent
            }
          }
        })
        resolve(null)
        if (data === '') {
          message.success('Upload Successfully')
          handleSearch()
        } else {
          store.commit('localRegistration/updateThirdPartyDataError', data)
          router.push({ name: 'Local Registration Data Upload Third Party Data Failed Reason' })
        }
        visibleUploadDrawer.value = false
      } catch (e) {
        reject(e)
      }
    }

    const handleDelete = (record: any)=>{
      Modal.confirm({
        title: 'Please confirm',
        content: 'Do you confirm to delete current record?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: async () => {
          await thirdPartyUploadDeleteAPI(record.id)
          queryThirdPartyData()
        }
      })
    }

    handleSearch()

    return {
      conditions,
      handleReset,
      handleSearch,
      pagination,
      columns,
      data,
      visibleUploadDrawer,
      handleToUpload,
      handleDownloadTemplate,
      uploadBu,
      queryThirdPartyData,
      handleUpload,
      handleExport,
      handleDelete
    }
  }
})
