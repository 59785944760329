
import { defineComponent, ref, watch, computed } from 'vue'
import type { PropType } from 'vue'
import useModel from '@/hooks/useModel'
import type { ThirdPartyDataFilename } from '@/views/LocalRegistration/type'
import {
  thirdPartyUploadFilenameList as thirdPartyUploadFilenameListAPI
} from '@/API/localRegistration'

export default defineComponent({
  name: 'UploadFileNameSelector',
  emits: [
    'update:value'
  ],
  props: {
    optionTitle: {
      type: String as PropType<string>,
      default: 'filename'
    },
    optionKey: {
      type: String as PropType<string>,
      default: 'filename'
    },
    beginTime: {
      type: String,
    },
    endTime: {
      type: String,
    },
    value: {
      type: Object,
    }
  },
  setup(props, { emit }) {
    const valueModel = useModel(props, 'value')

    const optionsRaw = ref<ThirdPartyDataFilename[]>([])

    const options = computed(() => {
      const keys = new Set()
      const uniqueOptions: ThirdPartyDataFilename[] = []
      optionsRaw.value.forEach(option => {
        if (!keys.has(option[props.optionKey])) {
          keys.add(option[props.optionKey])
          uniqueOptions.push(option)
        }
      })
      return uniqueOptions
    })

    watch([() => props.beginTime, () => props.endTime], async () => {
      if (props.beginTime && props.endTime) {
        optionsRaw.value = await thirdPartyUploadFilenameListAPI(props.beginTime, props.endTime)
      } else {
        optionsRaw.value = []
      }
      emit('update:value', undefined)
    }, {
      immediate: true
    })

    return {
      valueModel,
      options
    }
  }
})
